@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');

:root {
	--font-base: "DM Sans", sans-serif;
	--font-head: "Ubuntu", sans-serif;

	--primary-color: #edf2f8;  /* aliceblue color */
	--secondary-color: #ed6f63;  /* slateblue color */ 
	--white-color: #ffffff;
	--black-color: #030303;
	--lightGray-color: #e4e4e4;
	--silver-color: #cbcbcb;
	--slategray-color: #6b7688;
	--snow-color: #fef4f5;
	--whitesmoke-color: #f2f7fb;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
}